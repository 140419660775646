<template>
  <div>loading...</div>
</template>
<script>
import appApi from '@/api/modules/app.api.js';

import logging from '@/utils/logging';
import { getParam, getMultParam } from '@/utils/http-tools';
import { setToken } from '@/utils/auth';
export default {
  name: 'LoginCallback',
  created() {
    // 这里是在login页面监测到没有token，经过登录操作后跳转至的登录中转页面
    logging(true);
    if (getParam('session')) {
      this.getToken();
    } else {
      this.$router.push({ path: './' });
    }
  },
  methods: {
    async getToken() {
      let [data] = await appApi.getTradeToken({ session: getParam('session') });
      if (!data) return;
      setToken(data);
      await this.$store.dispatch('user/login');
      logging(false);
      // 获取从from开始往后的字符串(路由导航+业务参数)
      const urlParams = new URLSearchParams(window.location.search);
      const paramValue = urlParams.get('tab');
      let from = null;
      if (!paramValue) {
        from = getParam('from');
      } else {
        from = getMultParam(['from', 'endDate', 'tab']);
      }
      console.log(from);

      // const params = beginDate + endDate + tab;
      // 根据from生成对应的url跳转至指定的路由，若有携带参数则利用指定路由页面的业务代码处理，没有则常规跳转至指定路由即可
      from === 'undefined' ? window.location.replace(location.origin) : window.location.replace(location.origin + '/#' + from);
      // 手动push一下路由跳转，window.location不会带动Push路由的同步跳转
      this.$router.push({ path: './' });
    },
  },
};
</script>
<style lang="scss" scoped></style>
